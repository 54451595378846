import React from 'react';

export default class Home extends React.Component {
  render() {
    return (
      <div>
        <div data-collapse="none" data-animation="default" data-duration={400} className="navbar w-nav">
          <a href="index.html" className="brand w-nav-brand w--current">
            <div className="text-block-4">Stephen Abrahamsen</div>
          </a>
          <nav role="navigation" className="nav-menu w-nav-menu"></nav>
          <div className="w-nav-button">
            <div className="w-icon-nav-menu" />
          </div>
        </div>
        <div data-w-id="8d967f30-0f60-53ad-afff-f6be8eeb33d6" className="hero">
          <div data-w-id="8379a733-d2be-9ef6-7d74-5aa993d72c65" className="wrapper-title">
            <div className="_w-h1">
              <h1 data-w-id="eca8521f-4208-5159-fae5-908e9ea99674"  className="main-h1 home">Software.</h1>
            </div>
            <div className="_w-h1">
              <h1 data-w-id="a5b9f386-66ee-dfd4-322d-966155820ac1" className="main-h1 bold home">Evolved.</h1>
            </div>
            <div data-w-id="01ca0c99-bc4a-9067-fcdd-21f3e0abdd06"  className="home-subhead">
              <p>Creating and teaching creators</p>
            </div>
          </div>
        </div>
        <div className="section main">
          <div className="w-dyn-list">
            <div className="w-dyn-items">
              <div className="wrapper w-dyn-item">
                <div className="column background-image-2">
                  <div className="column _100vh">
                    <div className="project-info">
                      <div className="number">
                        <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://nexusoft.com"><h2 className="number zero">Nexusoft</h2></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-27">
            <div className="div-block-26">
              <div className="wrapper-list-links"><a href="https://twitter.com/wizebin" className="footer-links">Twitter</a><a href="https://www.linkedin.com/in/stephenabrahamsen/" className="footer-links">Linkedin</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
