import React, { Component } from 'react';
import './App.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/wizebin-com.webflow.css';
import Home from './pages/Home';
import Share from './pages/Share';

class App extends Component {
  state = { page: 'home' };
  navigate = (page) => {
    this.setState({ page });
  }
  render() {
    const { page } = this.state;

    if (page === 'home') {
      return (
        <Home navigate={this.navigate}/>
      );
    } else if (page === 'share') {
      return (
        <Share navigate={this.navigate}/>
      );
    }
  }
}

export default App;
